<template>
  <app-overlay loading-variable="sidebarOverlay">
    <div v-if="data">
      <h6 class="text-center">
        {{ $FormattedDateTime(data.createdAt) }}
      </h6>
      <b-card class="border rounded mb-1">
        <b-card-sub-title><span class="text-dark">Olay Türü:</span></b-card-sub-title>
        <hr class="m-50" />
        {{ data.incidentType }}
      </b-card>
      <b-card class="border rounded mb-1">
        <b-card-sub-title><span class="text-dark">Açıklama:</span></b-card-sub-title>
        <hr class="m-50" />
        {{ data.description }}
      </b-card>
      <div v-if="data.files.length">
        <hr />
        <app-button @click="showImages" :text="'Fotoğraflar' + ' ( ' + data.files.length + ' )'" size="md" block />
      </div>
    </div>
  </app-overlay>
</template>

<script>
import ImageGalleryModal from "../ImageGalleryModal.vue";
export default {
  computed: {
    data() {
      return this.$store.getters?.appSidebarItem;
    },
  },

  methods: {
    showImages() {
      this.$showAppModal({
        item: {
          route: "actions/incidents",
        },
        component: ImageGalleryModal,
        noFade: true,
        noBodyGap: true,
        size: "xl",
      });
    },
  },
};
</script>

<style></style>
