<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="startVisitForm">
      <div v-if="isDateExpired">
        <app-select-input
          name="Kişi"
          v-model="selectedOfficial"
          :options="officials"
          :reduce="(option) => option.auth._id"
          select_label="auth.fullname"
          placeholder="SMS Gönderilecek Kişi Seçiniz..."
          rules="required"
          :loading="$store.getters.selectBoxOverlay"
        />
        <hr />
        <app-button @click="startVisit" text="Kodu Gönder" block />
      </div>
      <div v-else>
        <app-input v-model="code" name="Sms Kodu" type="number" label="Sms Kodu:" placeholder="Kod Giriniz..." rules="required|length:4" @keyup.enter.native="checkCode" />
        <!-- timer -->
        <div class="d-flex justify-content-center">
          {{ timer }}
        </div>
        <hr />
        <app-button @click="checkCode" text="Onayla" block />
      </div>
    </validation-observer>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";
export default {
  components: { ValidationObserver },
  data() {
    return {
      code: null,
      timer: "00:00",
      countdown: null,
      selectedOfficial: null,
      officials: [],
      isDateExpired: true,
    };
  },
  computed: {
    company() {
      return this.$store.getters.visit?.company?._id;
    },
    visitId() {
      return this.$store.getters.visit?._id;
    },
    visit() {
      return this.$store.getters.visit;
    },
  },
  methods: {
    startVisit() {
      this.$validate(this.$refs.startVisitForm, () => {
        this.$axios
          .patch(
            `/visits/${this.visitId}/send-code`,
            {
              selectedOfficial: this.selectedOfficial,
            },
            { loading: "sidebar" }
          )
          .then((result) => {
            this.$store.commit("updateVisitCompleteCodeExpireDate", result.data.completeCodeExpireDate);
            this.checkDateExpired();
            this.startCountDown();
          })
          .catch((err) => {
            this.$emitter.$emit("Notification", { variant: "danger", title: "Hata Oluştu", message: err?.data?.message ?? null });
          });
      });
    },
    getOfficials() {
      this.$axios
        .get("/customer/list", { params: { company: this.company }, loading: "selectBox" })
        .then((result) => {
          this.officials = result.data;
        })
        .catch((err) => {
          this.$emitter.$emit("Notification", { variant: "danger", title: "Hata Oluştu", message: err?.data?.message ?? null });
        });
    },
    checkCode() {
      this.$validate(this.$refs.startVisitForm, () => {
        this.$axios
          .patch(
            `/visits/${this.visitId}/check-visit-code`,
            {
              code: this.code,
            },
            { loading: "sidebar" }
          )
          .then((result) => {
            this.$store.commit("updateVisitToCompleted", { id: this.visitId, status: result.data.status, completedDate: result.data.completedDate });
            this.$emitter.$emit("Notification", { variant: "success", title: "Başarılı", message: "Ziyaret Başarıyla Tamamlandı" });
            this.$store.commit("cleanAppSidebar");
          })
          .catch((err) => {
            this.$emitter.$emit("Notification", { variant: "danger", title: "Hata Oluştu", message: err?.data?.message ?? null });
          });
      });
    },
    checkDateExpired() {
      const completeCodeExpireDate = this.$store.getters.visit?.completeCodeExpireDate;
      this.isDateExpired = completeCodeExpireDate ? new Date(completeCodeExpireDate) - new Date().getTime() < 0 : true;
    },
    startCountDown() {
      this.countdown = setInterval(() => {
        const now = new Date().getTime();
        const distance = new Date(this.visit?.completeCodeExpireDate) - now;
        if (!distance || distance < 0) {
          clearInterval(this.countdown);
          this.timer = "00:00";
          this.isDateExpired = true;
        } else {
          const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((distance % (1000 * 60)) / 1000);
          const timer = (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds < 10 ? "0" + seconds : seconds);
          this.timer = timer;
        }
      }, 1000);
    },
  },
  mounted() {
    this.getOfficials();
    this.checkDateExpired();
    if (!this.isDateExpired) this.startCountDown();
  },
  destroyed() {
    clearInterval(this.countdown);
  },
};
</script>

<style></style>
