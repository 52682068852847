<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="planTrainingForm">
      <assigned-branches v-if="!visit" v-model="data.branch" />
      <div v-if="this.visit || this.data.branch">
        <app-select-input
          v-model="data.employees"
          name="Çalışanlar"
          select_label="fullname"
          placeholder="Çalışan Seçiniz..."
          :options="employeeOptions"
          label="Çalışan:"
          :reduce="(option) => ({ employee: option._id })"
          rules="required"
          multiple
        >
          <template #list-header>
            <div class="p-50 m-25 border rounded d-flex gap-3">
              <app-button text="Tümünü Seç" @click="selectAll" />
              <app-button text="Seçimleri Kaldır" @click="deSelectAll" />
            </div>
          </template>
        </app-select-input>
        <app-date-input v-if="!visit" v-model="data.trainingDate" name="Planlanan Eğitim Tarihi" label="Planlanan Eğitim Tarihi:" placeholder="Tarih Seçiniz..." rules="required" />
        <app-select-input v-model="selectedSubject" name="Konu" :options="subjectOptions" label="Eğitim Konusu:" placeholder="Konu Seçiniz..." rules="required" />
        <app-input v-if="selectedSubject === 'Diğer'" v-model="data.subject" name="Konu" placeholder="Konu..." label="Eğitim Konusu:" rules="required|max:70" />
        <app-select-input v-model="data.hours" name="Süre" :options="hourOptions" label="Süre (Saat):" placeholder="Süre Seçiniz..." rules="required" />
        <app-select-input
          v-model="data.instructor"
          name="Eğitim Veren"
          select_label="auth.fullname"
          placeholder="Seçiniz..."
          :options="physicianOptions"
          label="Eğitim Veren:"
          :reduce="(option) => option._id"
          :loading="isPhysicianOptionsLoading"
        />
      </div>
      <hr />
      <app-button @click="planTraining" :text="visit ? 'Oluştur' : 'Planla'" icon="PlusIcon" block />
      <hr />
    </validation-observer>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import assignedBranches from "../assignedBranches.vue";
export default {
  components: { ValidationObserver, assignedBranches },
  data() {
    return {
      hourOptions: ["2", "4", "8", "12", "16"],
      selectedSubject: null,
      physicianOptions: [],
      isPhysicianOptionsLoading: false,
      subjectOptions: ["Temel İSG", "İş Kazası Sonrası İşe Başlama", "Yüksekte Çalışma", "Kişisel Koruyucu Donanım", "Kaynaklı Çalışma", "Kimyasallarla Çalışma", "Diğer"],
      data: {
        trainingDate: null,
        branch: null,
        subject: null,
        hours: null,
        employees: [],
      },
      employeeOptions: [],
    };
  },
  computed: {
    selectedCompany() {
      return this.$store.getters.selectedCompany?._id;
    },
    selectedBranch() {
      return this.$store.getters.selectedBranch;
    },
    visit() {
      return this.$store.getters.visit;
    },
  },
  methods: {
    setData() {
      return {
        ...this.data,
        company: this?.visit?.company?._id ?? this.selectedCompany,
        branch: this?.visit?.branch?._id ?? this.data.branch,
        subject: this.selectedSubject === "Diğer" ? this.data.subject : this.selectedSubject,
        trainingDate: new Date(this.data.trainingDate),
        visit: this.visit?._id ?? undefined,
      };
    },
    planTraining() {
      this.$validate(this.$refs.planTrainingForm, () => {
        this.$store.dispatch("newTraining", this.setData());
      });
    },
    getEmployees() {
      !!(this.visit?.company?._id || this.selectedCompany) &&
        !!(this.visit?.branch?._id || this.data.branch) &&
        this.$axios
          .get("/employees/employee-options", {
            params: { company: this?.visit?.company?._id ?? this.selectedCompany, branch: this.visit?.branch?._id ?? this.data.branch, isArchived: false },
            loading: "sidebar",
          })
          .then((result) => {
            this.employeeOptions = result.data;
          })
          .catch((err) => {
            emitter.$emit("Notification", { variant: "danger", title: "Hata oluştu", message: err?.data?.message ?? null });
          });
    },
    getPhysicianOptions() {
      this.isPhysicianOptionsLoading = true;
      this.$store
        .dispatch("getPhysicianOptions")
        .then((result) => {
          this.isPhysicianOptionsLoading = false;
          this.physicianOptions = result;
        })
        .catch((err) => {
          this.isPhysicianOptionsLoading = false;
          console.log(err);
        });
    },
    selectAll() {
      this.data.employees = this.employees.map((item) => ({ employee: item._id }));
    },
    deSelectAll() {
      this.data.employees = [];
    },
  },
  mounted() {
    if (this.visit) this.data.trainingDate = new Date(this.visit?.startDate);
    this.data.branch = this.selectedBranch;
    this.getPhysicianOptions();
    this.getEmployees();
  },
  watch: {
    "data.branch": {
      handler() {
        this.getEmployees();
      },
    },
  },
};
</script>

<style></style>
