<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="updateStartDateForm">
      <app-date-input
        v-model="startDate"
        :min-date="new Date().setHours(0, 0, 0, 0)"
        name="Planlanan Ziyaret Tarihi"
        label="Planlanan Ziyaret Tarihi:"
        placeholder="Tarih Seçiniz..."
        rules="required"
      />
      <hr />
      <app-button text="Güncelle" block @click="updateStartDate" />
    </validation-observer>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";

export default {
  components: { ValidationObserver },
  data() {
    return {
      startDate: null,
    };
  },
  computed: {
    visit() {
      return this.$store.getters.visit;
    },
  },
  methods: {
    updateStartDate() {
      this.$validate(this.$refs.updateStartDateForm, () => {
        if (this.visit) this.$store.dispatch("updateVisitStartDate", { id: this.visit?._id, startDate: this.startDate });
      });
    },
  },
};
</script>

<style></style>
