<template>
  <app-select-input
    v-model="branch"
    class="w-100"
    :reduce="(option) => option._id"
    :options="branches"
    label="Sicil:"
    select_label="name"
    name="Sicil"
    placeholder="Sicil Seçiniz..."
    rules="required"
    @input="$emit('input', branch)"
    :loading="$store.getters.selectBoxOverlay"
  />
</template>

<script>
export default {
  data() {
    return {
      branch: null,
    };
  },
  computed: {
    selectedCompany() {
      return this.$store.getters.selectedCompany;
    },
    physician() {
      return this.$store.getters?.user?.physician._id;
    },
    selectedBranch() {
      return this.$store.getters.selectedBranch;
    },
    branches() {
      return this.$store.getters.assignedBranches;
    },
  },
  watch: {
    selectedCompany() {
      this.getAssignedBranches();
    },
  },
  mounted() {
    if (this.selectedBranch) {
      this.branch = this.selectedBranch;
      this.$emit("input", this.selectedBranch);
    }
  },
  methods: {
    getAssignedBranches() {
      this.selectedCompany && this.physician && this.$store.dispatch("getAssignedBranches", { physician: this.physician, company: this.selectedCompany?._id });
    },
  },
};
</script>

<style></style>
