<template>
  <app-overlay>
    <db-searchable-table v-model="filters" @changed="getData" :class="windowWidth < 767 ? 'mb-0' : 'border mb-50'" :items="items" :fields="fields" searchable>
      <template #modalbutton>
        <app-button class="flex-1-md" text="Yeni Ekle" icon="PlusIcon" size="md" @click="$showAppSidebar('Tespit / Öneri Defteri Ekle', detectionAndSuggestionForm)" />
      </template>
      <template #actions="{ item }">
        <app-icon-button icon="EyeIcon" variant="gradient-danger" @click="showDetailSidebar(item)" />
      </template>
    </db-searchable-table>
  </app-overlay>
</template>

<script>
import detectionAndSuggestionForm from "./detectionAndSuggestionForm.vue";
import showDetails from "./showDetails.vue";

export default {
  data() {
    return {
      filters: {},
      showDetails,
      detectionAndSuggestionForm,
    };
  },
  computed: {
    items() {
      return this.$store.getters?.detectionAndSuggestions?.data || [];
    },
    totalCount() {
      return this.$store.getters?.detectionAndSuggestions?.total || 0;
    },
    fields() {
      return this.windowWidth < 767
        ? [
            { key: "title", label: "Başlık" },
            { key: "actions", label: "İşlemler" },
          ]
        : [
            ...(this.selectedBranch || this.visit ? [] : [{ key: "branch.name", label: "Sicil" }]),
            { key: "title", label: "Başlık" },
            { key: "createdAt", label: "Oluşturulma Tarihi", formatDateTime: true },
            { key: "actions", label: "İşlemler" },
          ];
    },
    selectedCompany() {
      return this.$store.getters.selectedCompany;
    },
    physician() {
      return this.$store.getters?.user?.physician?._id;
    },
    selectedBranch() {
      return this.$store.getters.selectedBranch;
    },
    windowWidth() {
      return this.$store.getters["app/windowWidth"];
    },
    assignedBranches() {
      return this?.selectedCompany?.assignedBranches.map((item) => item?._id) ?? [];
    },
    visit() {
      return this.$store.getters.visit;
    },
  },
  methods: {
    showDetailSidebar(item) {
      this.$axios
        .get("/actions/detection-suggestion", { params: { _id: item._id }, loading: "table" })
        .then((res) => {
          this.$showAppSidebar(res.data.title, showDetails, res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getData() {
      const query = this.visit
        ? {
            visit: this.visit?._id,
          }
        : {
            company: this.selectedCompany?._id,
            ...(!this.selectedBranch ? { "branch[$in]": this.assignedBranches } : { branch: this.selectedBranch }),
          };
      this.selectedCompany &&
        this.$axios
          .get("/actions/detection-suggestions/paginated", {
            params: {
              ...this.filters,
              ...query,
              populates: !this.selectedBranch ? JSON.stringify(["branch"]) : undefined,
            },
            loading: "table",
          })
          .then((result) => {
            this.$store.commit("setDetectionAndSuggestions", result.data);
          })
          .catch((err) => {
            console.log(err);
          });
    },
  },
  watch: {
    selectedCompany() {
      this.getData();
    },
    selectedBranch() {
      this.getData();
    },
  },
  destroyed() {
    this.$store.commit("setDetectionAndSuggestions", []);
  },
};
</script>

<style></style>
