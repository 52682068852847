<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="detectionAndSuggestionForm">
      <assigned-branches v-if="!visit" v-model="data.branch" />
      <app-input v-model="data.title" name="Başlık" placeholder="Başlık..." label="Başlık:" rules="required" />
      <app-textarea-input v-model="data.description" name="Açıklama" label="Açıklama:" placeholder="Açıklama..." rules="required" />
      <hr />
      <small>
        - Sadece JPG ve PNG dosya formatı yüklenebilir. <br />
        - Bir resim en fazla 2 MB boyutunda olabilir.<br />
        - En fazla 10 adet resim yükleyebilirsiniz.
      </small>
      <hr />
      <app-file-input v-model="data.files" accept="image/jpeg,image/png" multiple />
      <small class="text-danger">{{ error }}</small>
      <hr />
      <app-button text="Oluştur" block @click="create" />
      <hr />
    </validation-observer>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import assignedBranches from "../../assignedBranches.vue";

export default {
  components: { ValidationObserver, assignedBranches },
  data() {
    return {
      data: {
        files: [],
      },
    };
  },
  computed: {
    error() {
      if (this?.data?.files?.length > 10) return "En fazla 10 dosya yükleyebilirsiniz.";
      return null;
    },
    selectedCompany() {
      return this.$store.getters.selectedCompany?._id;
    },
    visit() {
      return this.$store.getters.visit;
    },
  },
  methods: {
    setData() {
      const formData = new FormData();
      formData.append("title", this.data.title);
      this.data.files.forEach((file) => formData.append("files", file));
      formData.append("description", this.data.description);
      formData.append("company", this?.visit?.company?._id ?? this.selectedCompany);
      formData.append("branch", this?.visit?.branch?._id ?? this.data.branch);
      this.visit && formData.append("visit", this.visit._id);
      return formData;
    },
    create() {
      this.$validate(this.$refs.detectionAndSuggestionForm, () => {
        this.$axios
          .post("/actions/detection-suggestion", this.setData(), { loading: "sidebar" })
          .then((res) => {
            this.$store.commit("newDetectionAndSuggestion", res.data);
            this.$store.commit("cleanAppSidebar");
            this.$emitter.$emit("Notification", { variant: "success", title: "İşlem Başarılı", message: "Tespit veya Öneri başarıyla oluşturuldu." });
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
  },
};
</script>

<style></style>
